import types from "@/utils/mutation-types";
import errorHandler from "@/utils/error";
import axios from "@/utils/axios";

const brandStore = {
  state: () => ({
    brands: [],
  }),
  mutations: {
    [types.SET_BRANDS](state, brands) {
      state.brands = brands;
    },
  },
  actions: {
    async getBrands({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/documentbrands`
        );

        if (res.status === 200) {
          commit(types.SET_BRANDS, res.data.brands);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    brands(state) {
      return state.brands;
    },
  },
};

export default brandStore;
