<template>
  <div class="alerts-container">
    <div
      v-for="{ alert, backgroundColor, color, id } in alerts"
      :key="id"
      class="alert rounded capitalize opacity-80"
      :style="{ backgroundColor, color }"
    >
      {{ alert }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Alerts",

  computed: {
    ...mapGetters(["alerts"]),
  },
};
</script>

<style scoped >
.alerts-container {
  position: fixed;
  z-index: 10000;
  right: 0;
  margin: 1rem 1rem 0 0;
  top: 0;
}
.alert {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.alert:not(:first-child) {
  margin-top: 1rem;
}
</style>