import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/components/pages/Login"),

    meta: {
      layout: "LoginLayout",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/components/pages/ForgotPassword"),

    meta: {
      layout: "LoginLayout",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/components/pages/ResetPassword"),

    meta: {
      layout: "LoginLayout",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/components/pages/Dashboard"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/trending",
    name: "Trending",
    component: () => import("@/components/pages/Trending"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/orders/:id",
    name: "Single Order",
    component: () => import("@/components/pages/Order"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/order/new",
    name: "NewOrderInquiry",
    component: () => import("@/components/pages/NewOrderInquiry"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/catalogs",
    name: "Catalog",
    component: () => import("@/components/pages/Catalogs/index"),
    meta: {
      layout: "MainLayout",
    },
    children: [
      {
        path: "",
        component: () => import("@/components/pages/Catalogs/CatalogFolders"),
        name: "CatalogFolders",
      },
      {
        path: ":brandid",
        name: "SingleCatalogFolder",
        component: () =>
          import("@/components/pages/Catalogs/SingleCatalogFolder"),
      },
    ],
  },
  {
    path: "/partmanuals",
    name: "PartManuals",
    component: () => import("@/components/pages/PartManuals/index"),
    meta: {
      layout: "MainLayout",
    },
    children: [
      {
        path: "",
        component: () =>
          import("@/components/pages/PartManuals/BrandPartManualFolders"),
        name: "PartManualFolders",
      },
      {
        path: ":brandid",
        component: () =>
          import("@/components/pages/PartManuals/SingleBrandPartManualFolder"),
        name: "SinglePartManualFolder",
      },
    ],
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("@/components/pages/Support"),

    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/ticket",
    name: "NewTicket",
    component: () => import("@/components/pages/NewTicket"),

    meta: {
      layout: "MainLayout",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name &&
    to.name.match(/(Login|ResetPassword|ForgotPassword)/gi) &&
    store.getters.isAuthenticated
  ) {
    next({ name: "Trending" });
    return;
  }
  if (
    to.name &&
    !to.name.match(/(Login|ResetPassword|ForgotPassword)/gi) &&
    !store.getters.isAuthenticated
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
