<template>
  <div>
    <div>
      <div class="flex h-screen w-screen bg-white dark:bg-gray-800 font-roboto">
        <div
          :class="sidebarOpen ? 'block' : 'hidden'"
          @click="sidebarOpen = false"
          class="
            fixed
            z-20
            inset-0
            bg-black
            opacity-50
            transition-opacity
            lg:hidden
          "
        ></div>
        <!-- Sidebar -->
        <div
          :class="
            sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
          "
          class="
            sidebar
            fixed
            z-30
            flex flex-col
            inset-y-0
            left-0
            w-56
            transition
            duration-300
            transform
            overflow-y-auto overflow-x-hidden
            lg:translate-x-0 lg:static lg:inset-0
          "
          :style="{ width: sidebarCollapsed ? '4rem' : '15rem' }"
        >
          <div
            style="background-color: #f7f7f7"
            class="flex items-center w-full justify-center pt-5 pb-4"
          >
            <div class="flex items-center justify-center">
              <logosvg :sidebarCollapsed="sidebarCollapsed"></logosvg>
            </div>
          </div>

          <ul class="sidebar-menu flex flex-col mt-10 text-left">
            <router-link to="/trending">
              <li
                :class="{
                  'py-3 px-6': !sidebarCollapsed,
                  'sidebar-slim-link': sidebarCollapsed,
                }"
                class="flex items-center text-sm text-black cursor-pointer"
              >
                <svg
                  class="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  :width="!sidebarCollapsed ? '20' : '40'"
                  :height="!sidebarCollapsed ? '20' : '40'"
                  fill="none"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="3" y="3" width="7" height="7"></rect>
                  <rect x="14" y="3" width="7" height="7"></rect>
                  <rect x="14" y="14" width="7" height="7"></rect>
                  <rect x="3" y="14" width="7" height="7"></rect>
                </svg>
                <span v-show="!sidebarCollapsed">Trending</span>
              </li>
            </router-link>
            <li>
              <router-link
                :class="{
                  'py-3 px-6': !sidebarCollapsed,
                  'sidebar-slim-link': sidebarCollapsed,
                }"
                class="flex items-center text-sm text-black cursor-pointer"
                to="/dashboard"
              >
                <svg
                  class="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  :width="!sidebarCollapsed ? '20' : '40'"
                  :height="!sidebarCollapsed ? '20' : '40'"
                  fill="none"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                  />
                  <path d="M14 3v5h5M12 18v-6M9 15h6" />
                </svg>
                <span v-show="!sidebarCollapsed">Dashboard</span>

                <svg
                  v-show="!sidebarCollapsed"
                  class="m-auto mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </router-link>
            </li>
            <li>
              <router-link
                :class="{
                  'py-3 px-6': !sidebarCollapsed,
                  'sidebar-slim-link': sidebarCollapsed,
                }"
                class="flex items-center text-sm text-black cursor-pointer"
                to="/catalogs"
              >
                <svg
                  class="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  :width="!sidebarCollapsed ? '20' : '40'"
                  :height="!sidebarCollapsed ? '20' : '40'"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                  <path
                    d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                  ></path>
                </svg>
                <span v-show="!sidebarCollapsed">Catalogs & Brochures</span>
              </router-link>
            </li>
            <li>
              <router-link
                :class="{
                  'py-3 px-6': !sidebarCollapsed,
                  'sidebar-slim-link': sidebarCollapsed,
                }"
                class="flex items-center text-sm text-black cursor-pointer"
                to="/partmanuals"
              >
                <svg
                  class="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  :width="!sidebarCollapsed ? '20' : '40'"
                  :height="!sidebarCollapsed ? '20' : '40'"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                  />
                  <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
                </svg>
                <span v-show="!sidebarCollapsed">Parts Manuals</span>
              </router-link>
            </li>
            <li>
              <router-link
                :class="{
                  'py-3 px-6': !sidebarCollapsed,
                  'sidebar-slim-link': sidebarCollapsed,
                }"
                class="flex items-center text-sm text-black cursor-pointer"
                to="/order/new"
              >
                <svg
                  class="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  :width="!sidebarCollapsed ? '20' : '40'"
                  :height="!sidebarCollapsed ? '20' : '40'"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="10" cy="20.5" r="1" />
                  <circle cx="18" cy="20.5" r="1" />
                  <path
                    d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1"
                  />
                </svg>
                <span v-show="!sidebarCollapsed">Order Inquiries</span>

                <svg
                  v-show="!sidebarCollapsed"
                  class="m-auto mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </router-link>
            </li>

            <li>
              <router-link
                :class="{
                  'py-3 px-6': !sidebarCollapsed,
                  'sidebar-slim-link': sidebarCollapsed,
                }"
                class="flex items-center text-sm text-black cursor-pointer"
                to="/support"
              >
                <svg
                  class="mr-2"
                  :width="!sidebarCollapsed ? '20' : '40'"
                  :height="!sidebarCollapsed ? '20' : '40'"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
                  ></path>
                </svg>
                <span v-show="!sidebarCollapsed">Customer Support</span>
              </router-link>
            </li>
            <!-- TOGGLE -->
          </ul>
          <div
            :class="{
              'justify-end': !sidebarCollapsed,
              'justify-center items-center': sidebarCollapsed,
            }"
            class="mt-auto flex p-6"
          >
            <button
              class="sidebar-collapse-btn"
              :class="{ 'sidebar-collapse-btn--closed': sidebarCollapsed }"
              @click="sidebarCollapsed = !sidebarCollapsed"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 8l-4 4 4 4M16 12H9" />
              </svg>
            </button>
          </div>
        </div>
        <!-- / Sidebar -->
        <!-- Main Content -->
        <div
          :class="{
            'main-content-sidebar-full': !sidebarCollapsed,
            'main-content-sidebar-collapse': !sidebarCollapsed,
          }"
          class="flex-1 max-w-full flex flex-col h-screen"
        >
          <div class="flex justify-between items-center p-2 topbar">
            <div class="flex items-center space-x-4 lg:space-x-0">
              <button
                @click="sidebarOpen = true"
                class="
                  text-gray-500
                  dark:text-gray-300
                  focus:outline-none
                  lg:hidden
                "
              >
                <svg
                  class="h-6 w-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6H20M4 12H20M4 18H11"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>

              <div class="p-1">
                <!-- <h1 class="text-xs font-bold text-gray-800 dark:text-white">
                  Customer ID: {{ currentUser.customerId }}
                </h1>
                <h1 class="text-xs font-bold text-gray-800 dark:text-white">
                  Customer:
                  {{
                    currentUser.customerCompany &&
                    currentUser.customerCompany.name
                      ? currentUser.customerCompany.name
                      : ""
                  }}
                </h1> -->
              </div>
            </div>

            <div class="flex items-center space-x-4">
              <div class="relative">
                <button
                  :class="{
                    'unread-messages-button': unreadSupportTickets.length
                      ? true
                      : false,
                  }"
                  @click="
                    notificationsDropdownOpen = !notificationsDropdownOpen
                  "
                  class="
                    flex
                    text-gray-600
                    dark:text-gray-300
                    focus:outline-none
                  "
                >
                  <svg
                    class="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div
                  v-show="notificationsDropdownOpen"
                  @click="notificationsDropdownOpen = false"
                  style="z-index: 10999"
                  class="fixed top-0 right-0 bottom-0 left-0"
                ></div>
                <div
                  v-show="notificationsDropdownOpen"
                  class="absolute shadow bg-white rounded"
                  style="right: 50%; z-index: 11000"
                >
                  <div
                    style="min-width: 200px"
                    class="px-3 border-b cursor-pointer hover:bg-gray-100 py-1"
                    v-for="ticket in unreadSupportTickets"
                    :key="ticket._id"
                    @click="
                      $router.push(`/support`),
                        (notificationsDropdownOpen = false)
                    "
                  >
                    <h2 class="font-bold">#{{ ticket.ticketId }}</h2>
                    <p class="text-sm text-gray-400">
                      Unread messages in ticket.
                    </p>
                  </div>
                </div>
              </div>

              <div x-data="{ dropdownOpen: false }" class="relative">
                <button
                  @click="dropdownOpen = !dropdownOpen"
                  class="
                    flex
                    items-center
                    space-x-2
                    relative
                    focus:outline-none
                  "
                >
                  <h2
                    class="
                      text-gray-700
                      dark:text-gray-300
                      text-xs text-left
                      hidden
                      sm:block
                    "
                  >
                    {{ currentUser.contactName }} <br />
                    Customer ID: {{ currentUser.customerId }}<br />
                    Customer:
                    {{
                      currentUser.customerCompany &&
                      currentUser.customerCompany.name
                        ? currentUser.customerCompany.name
                        : ""
                    }}
                  </h2>

                  <img
                    class="
                      h-9
                      w-9
                      rounded-full
                      border-2 border-black
                      object-cover
                    "
                    src="/user-pic.jpg"
                    alt="Your avatar"
                  />
                </button>

                <div
                  v-if="dropdownOpen"
                  @click="dropdownOpen = false"
                  class="fixed z-50 top-0 bottom-0 left-0 right-0"
                ></div>

                <div
                  style="z-index: 1000"
                  class="
                    absolute
                    right-0
                    mt-2
                    w-48
                    bg-white
                    rounded-md
                    overflow-hidden
                    shadow-xl
                    z-10
                  "
                  v-if="dropdownOpen"
                  x-transition:enter="transition ease-out duration-100 transform"
                  x-transition:enter-start="opacity-0 scale-95"
                  x-transition:enter-end="opacity-100 scale-100"
                  x-transition:leave="transition ease-in duration-75 transform"
                  x-transition:leave-start="opacity-100 scale-100"
                  x-transition:leave-end="opacity-0 scale-95"
                >
                  <!-- <a
                    href="#"
                    class="
                      block
                      px-4
                      py-2
                      text-sm text-gray-700
                      hover:bg-gray-600 hover:text-white
                    "
                    >Profile</a
                  >
                  <a
                    href="#"
                    class="
                      block
                      px-4
                      py-2
                      text-sm text-gray-700
                      hover:bg-gray-600 hover:text-white
                    "
                    >Tickets</a
                  > -->
                  <button
                    @click="logout"
                    class="
                      text-left
                      block
                      w-full
                      px-4
                      py-2
                      text-sm text-gray-700
                      hover:bg-gray-600 hover:text-white
                    "
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Global Page Load Indicator -->
          <div v-if="loading" class="w-full -mt-4 -mb-1.5">
            <div class="w-full overflow-hidden">
              <div
                class="w-1/2 inline-block relative fluentProgressBar-waiting"
              ></div>
            </div>
          </div>
          <!-- / Global Page Load Indicator -->
          <slot> </slot>
        </div>
        <!-- / Main Content -->
      </div>
    </div>
  </div>
</template>

<script>
import logosvg from "../base/logosvg";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MainLayout",
  data() {
    return {
      sidebarOpen: false,
      sidebarCollapsed: false,
      dropdownOpen: false,
      notificationsDropdownOpen: false,
    };
  },
  methods: {
    ...mapActions(["logout", "getSupportTickets"]),
  },
  computed: {
    ...mapGetters(["currentUser", "loading", "unreadSupportTickets"]),
  },
  mounted() {
    this.getSupportTickets();
  },
  components: { logosvg },
};
</script>

<style lang="css" scoped>
.unread-messages-button {
  position: relative;
}
.unread-messages-button::after {
  content: "";
  width: 10px;
  position: absolute;
  left: 0%;
  top: 0;
  height: 10px;
  background: #d2ae6d;
  border-radius: 50%;
}
.topbar {
  background-color: #f7f7f7;
}
.main-content-sidebar-full {
  max-width: calc(100vw - 4rem);
} /*!sidebarCollapsed,*/
.main-content-sidebar-collapse {
  max-width: calc(100vw - 15rem);
} /*!sidebarCollapsed,*/

@media (max-width: 1024px) {
  .main-content-sidebar-collapse,
  .main-content-sidebar-full {
    max-width: 100vw;
  }
}
.sidebar {
  background-color: #eeeeee;
  transition: all 0.2s;
}
.sidebar-menu li {
  width: 100%;
}
.sidebar-menu li:hover {
  background-color: #d4b376;
}
.sidebar-slim-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.sidebar-collapse-btn {
  transition: all 0.5s;
}
.sidebar-collapse-btn--closed {
  transform: rotate(180deg);
}
.fluentProgressBar-normal {
  background-color: #d49100;
  height: 4px;
}
.fluentProgressBar-waiting {
  background: rgba(0, 120, 212, 0);
  background: -moz-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(212, 138, 0) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 120, 212, 0)),
    color-stop(51%, rgb(212, 138, 0)),
    color-stop(100%, rgba(0, 120, 212, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(212, 138, 0) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(212, 138, 0) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(212, 138, 0) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 120, 212, 0) 0%,
    rgba(212, 138, 0) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0078d4', endColorstr='#0078d4', GradientType=1 );
  height: 4px;
  -webkit-animation: progressBarAnimation 2s linear infinite;
  animation: progressBarAnimation 2s linear infinite;
}
@keyframes progressBarAnimation {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
</style>