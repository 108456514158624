import types from "@/utils/mutation-types";
import errorHandler from "@/utils/error";
import axios from "@/utils/axios";
import moment from "moment";

const statisticStore = {
  state: () => ({
    orderStatus: {
      pricing: 0,
      onHold: 0,
      approved: 0,
      completed: 0,
      rejected: 0,
    },
    placedOrderStats: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      datasets: [
        {
          label: "Placed Orders",
          data: [34, 65, 89, 87, 54, 43, 67],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          label: "Total Orders",
          fill: false,
          data: [65, 59, 80, 81, 56, 55, 40],
          borderColor: "#F97D75",
          tension: 0.1,
        },
      ],
    },
    paymentChartData: {
      labels: ["Credit Limit", "Outstanding", "Due"],
      datasets: [
        {
          label: "Credit Limit",
          data: [1, 1, 1],
          backgroundColor: ["#4FC1DB", "#ECA11F", "#DF3512"],
          borderWidth: 1,
        },
        // {
        //   label: "Outstanding",
        //   data: [1],
        //   backgroundColor: ["#ECA11F"],
        //   borderWidth: 1,
        // },
        // {
        //   label: "Due",
        //   data: [1],
        //   backgroundColor: ["#DF3512"],
        //   borderWidth: 1,
        // },
      ],
    },
  }),
  mutations: {
    [types.SET_ORDER_STATUS_STATS](state, stats) {
      state.orderStatus = stats;
    },
    [types.SET_PLACED_ORDER_STATS](state, stats) {
      state.placedOrderStats.labels = [];
      state.placedOrderStats.datasets[0].data = [];
      state.placedOrderStats.datasets[1].data = [];
      for (const key in stats) {
        state.placedOrderStats.labels.push(moment(key).format("MMM"));
        state.placedOrderStats.datasets[0].data.push(stats[key].placed || 0);
        state.placedOrderStats.datasets[1].data.push(
          stats[key].totalOrders || 0
        );
      }
    },
    [types.SET_PAYMENT_STATS](state, stats) {
      state.paymentChartData.datasets[0].data[0] = stats.creditLimit || 0;
      state.paymentChartData.datasets[0].data[1] = stats.outstanding;
      state.paymentChartData.datasets[0].data[2] = stats.due;
    },
  },
  actions: {
    async getOrderStatusStats({ getters, commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/cportal/stats/orderstatus`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.SET_ORDER_STATUS_STATS, res.data.stats);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async getPlacedOrderStats({ getters, commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/cportal/stats/placedorders`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.SET_PLACED_ORDER_STATS, res.data.stats);
        }
      } catch (e) {
        errorHandler(e);
      }
    },

    async getPaymentStats({ getters, commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/cportal/stats/payments`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(types.SET_PAYMENT_STATS, res.data.stats);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    orderStatusStatsData(state) {
      const arr = [];
      arr[0] = state.orderStatus["pricing"];
      arr[1] = state.orderStatus["onHold"];
      arr[2] = state.orderStatus["approved"];
      arr[3] = state.orderStatus["completed"];
      arr[4] = state.orderStatus["rejected"];
      return arr;
    },
    placedOrderStatsData(state) {
      return state.placedOrderStats;
    },
    paymentChartData(state) {
      return state.paymentChartData;
    },
  },
};

export default statisticStore;
