import types from "@/utils/mutation-types";
import errorHandler from "@/utils/error";
import axios from "@/utils/axios";

const partManualStore = {
  state: () => ({
    partManuals: [],
    partManualSearchString: "",
  }),
  mutations: {
    [types.SET_PART_MANUALS](state, partmanuals) {
      state.partManuals = partmanuals;
    },
    [types.SET_PART_MANUAL_SEARCH_VAL](state, searchValue) {
      state.partManualSearchString = searchValue;
    },
  },
  actions: {
    async getPartManuals({ commit, getters }, data = {}) {
      try {
        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/manuals?page=1`;

        const { folderid, brand } = data;

        if (folderid) {
          uri = `${uri}&folder=${folderid}`;
        } else if (brand && !getters.partManualSearchValue)
          uri = `${uri}&brand=${brand}`;

        if (getters.partManualSearchValue) {
          uri = `${uri}&search=${getters.partManualSearchValue}`;
        }

        const res = await axios.get(uri);

        if (res.status === 200) {
          commit(types.SET_PART_MANUALS, res.data.manuals);
        }
        commit(types.SET_LOADING, false);
      } catch (e) {
        commit(types.SET_LOADING, false);
        errorHandler(e);
      }
    },
  },
  getters: {
    partManuals(state) {
      return state.partManuals;
    },
    partManualSearchValue(state) {
      return state.partManualSearchString;
    },
  },
};

export default partManualStore;
