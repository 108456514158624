import types from "@/utils/mutation-types";
import errorHandler from "@/utils/error";
import axios from "@/utils/axios";

const catalogStore = {
  state: () => ({
    catalogs: [],
    catalogFolders: [],
  }),
  mutations: {
    [types.SET_CATALOGS](state, catalogs) {
      state.catalogs = catalogs;
    },
    [types.SET_CATALOG_FOLDERS](state, folders) {
      state.catalogFolders = folders;
    },
  },
  actions: {
    async getCatalogFolders({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/catalogues/folders`
        );

        if (res.status === 200) {
          commit(types.SET_CATALOG_FOLDERS, res.data.folders);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
    async getCatalogs({ commit }, data = {}) {
      try {
        const { brand } = data;

        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/catalogues`;

        if (brand) uri = `${uri}?brand=${brand}`;

        const res = await axios.get(uri);

        if (res.status === 200) {
          commit(types.SET_CATALOGS, res.data.catalogues);
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    catalogs(state) {
      return state.catalogs;
    },
    catalogFolders(state) {
      return state.catalogFolders;
    },
  },
};

export default catalogStore;
