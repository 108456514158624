import types from "@/utils/mutation-types";

const uiStore = {
  state: () => ({
    loading: false,
    sidebarCollapsed: false,
  }),
  mutations: {
    [types.SET_SIDEBAR_COLLAPSE_STATE](state, status) {
      state.sidebarCollapsed = status;
    },
    [types.SET_LOADING](state, val) {
      state.loading = val;
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },

    sidebarCollapsed(state) {
      return state.sidebarCollapsed;
    },
  },
};


export default uiStore;
