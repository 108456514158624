import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import "./assets/tailwind.css";

import moment from "moment";

import MainLayout from "@/components/layout/MainLayout";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const app = createApp(App)
  .use(store)
  .use(router);

app.component("MainLayout", MainLayout);

app.config.globalProperties.$moment = moment;

app.mount("#app");
