import { createStore } from "vuex";

import auth from "./modules/auth";
import alerts from "./modules/alert";
import ui from "./modules/ui";
import orders from "./modules/orders";
import manuals from "./modules/partmanuals";
import manualFolders from "./modules/partmanualFolders";
import catalogs from "./modules/catalogs";
import statistics from "./modules/statistics";
import brands from "./modules/brands";
import supportTickets from "./modules/supporttickets";

export default createStore({
  modules: {
    auth,
    alerts,
    orders,
    ui,
    manuals,
    catalogs,
    statistics,
    brands,
    manualFolders,
    supportTickets,
  },
});
