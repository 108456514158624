<template>
  <Alerts />
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import Alerts from "@/components/base/Alerts";
export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  components: {
    Alerts,
  },
};
</script>


<style>
.slim-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.slim-scrollbar::-webkit-scrollbar {
  width: 16px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>