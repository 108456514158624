import types from "@/utils/mutation-types";
import errorHandler from "@/utils/error";
import axios from "@/utils/axios";

const manualFolderStore = {
  state: () => ({
    manualFolders: [],
  }),
  mutations: {
    [types.SET_MANUAL_FOLDERS](state, manualFolders) {
      state.manualFolders = manualFolders;
    },
  },
  actions: {
    async getManualFolders({ commit, dispatch }, { brand, folder } = {}) {
      try {
        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/manualfolders`;

        if (brand) uri = `${uri}?brand=${brand}`;

        if (folder) uri = `${uri}&folder=${folder}`;

        const res = await axios.get(uri);
        if (res.status === 200) {
          commit(types.SET_MANUAL_FOLDERS, res.data.folders);
          dispatch("getPartManuals", {
            folderid: folder,
            brand,
          });
        }
      } catch (e) {
        errorHandler(e);
      }
    },
  },
  getters: {
    manualFolders(state) {
      return state.manualFolders;
    },
  },
};

export default manualFolderStore;
