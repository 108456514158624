import mutations from "@/utils/mutation-types";
import alertTypes from "@/utils/alert-types";
import errorHandler from "@/utils/error";
import axios from "@/utils/axios";

const orderStore = {
  state: () => ({
    orders: [],
    selectedOrder: "",
    page: 1,
    totalPages: 0,
    filters: [
      /*{ type: "customerStatus", value: "onHold" }*/
    ],
    outstandingFilter: false,
    orderSort: "",
    orderSearchString: "",
  }),
  mutations: {
    [mutations.SET_ORDERS](state, orders) {
      state.orders = orders;
    },
    [mutations.SET_SELECTED_ORDER](state, order) {
      state.selectedOrder = order;
    },
    [mutations.UPDATE_ORDER_IN_LIST](state, order) {
      state.orders = state.orders.map((o) => {
        if (o._id !== order._id) return o;
        else return order;
      });
    },
    [mutations.SET_PAGE](state, page) {
      state.page = page;
    },
    [mutations.SET_TOTAL_PAGES](state, totalPages) {
      state.totalPages = totalPages;
    },
    [mutations.CLEAR_FILTERS](state) {
      state.filters = [];
      state.outstandingFilter = false;
    },
    [mutations.CLEAR_STATUS_FILTERS](state) {
      state.filters = state.filters.filter((f) => {
        return f.type !== "inquiryStatus" && f.type !== "customerStatus";
      });
      state.outstandingFilter = false;
    },
    [mutations.ADD_FILTERS](state, filters) {
      state.filters.push(...filters);
    },
    [mutations.SET_OUTSTANDING_FILTER](state) {
      state.outstandingFilter = true;
    },
    [mutations.CLEAR_OUTSTANDING_FILTER](state) {
      state.outstandingFilter = false;
    },
    [mutations.SET_ORDER_SORT](state, sort) {
      state.orderSort = sort;
    },
    [mutations.CLEAR_ORDER_SORT](state) {
      state.orderSort = "";
    },
    [mutations.SET_ORDER_SEARCH_STRING](state, val) {
      state.orderSearchString = val;
    },
  },
  actions: {
    async getOrders({ getters, commit }) {
      try {
        const page = getters.currentOrderPage;
        const queryPage = page || 1;

        commit(mutations.SET_LOADING, true);

        let uri = `${process.env.VUE_APP_API_HOST}/api/v1/cportal/orders?page=${queryPage}`;

        if (getters.orderFilters.length) {
          getters.orderFilters.forEach((filter) => {
            uri = `${uri}&${filter.type}=${filter.value}`;
          });
        }

        if (getters.orderOutsandingFilter) {
          uri = `${uri}&outstanding=${getters.orderOutsandingFilter}`;
        }

        if (getters.orderSearchString) {
          uri = `${uri}&search=${getters.orderSearchString}`;
        }

        if (getters.orderSort) {
          uri = `${uri}&sort=${getters.orderSort}`;
        }

        console.log(getters.orderSort);

        const res = await axios.get(uri, {
          headers: {
            authorization: getters.bearerToken,
          },
        });

        if (res.status === 200) {
          commit(mutations.SET_ORDERS, res.data.orders);
          commit(mutations.SET_TOTAL_PAGES, res.data.totalPages);
        }
        commit(mutations.SET_LOADING, false);
      } catch (e) {
        commit(mutations.SET_LOADING, false);

        errorHandler(e);
      }
    },
    async getSingleOrder({ getters, commit }, id) {
      try {
        commit(mutations.SET_LOADING, true);
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/cportal/orders/${id}`,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(mutations.SET_SELECTED_ORDER, res.data.order);
        }
        commit(mutations.SET_LOADING, false);
      } catch (e) {
        commit(mutations.SET_LOADING, false);
        errorHandler(e);
      }
    },
    async placeOrder({ commit, getters, dispatch }, { file, id }) {
      try {
        const fd = new FormData();
        fd.append("customerDoc", file);

        commit(mutations.SET_LOADING, true);

        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/cportal/orders/${id}/placeorder`,
          fd,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          commit(mutations.UPDATE_ORDER_IN_LIST, res.data.order);
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "order placed!",
          });
          dispatch("getSingleOrder", getters.selectedOrder._id);
        }

        commit(mutations.SET_LOADING, false);
      } catch (e) {
        commit(mutations.SET_LOADING, false);
        errorHandler(e);
      }
    },
    async newOrderInquiry({ getters, dispatch, commit }, { data, files }) {
      try {
        commit(mutations.SET_LOADING, true);
        const fd = new FormData();

        fd.append("customerEmail", data.customerEmail);
        fd.append("subject", data.subject);
        fd.append("description", data.description);
        fd.append("modelNumber", data.modelNumber);
        fd.append("serialNumber", data.serialNumber);
        data.items.forEach((item, i) => {
          fd.append("items[" + i + "]", JSON.stringify(item));

          if (item.files && item.files.length) {
            let count = 1;
            item.files.forEach((file) => {
              fd.append(
                "itemImages",
                file,
                `${item.description}-${count}.${file.name.split(".")[1]}`
              );
              count++;
            });
          }
        });

        if (files.length) {
          files.forEach((file) => {
            fd.append("attachments", file);
          });
        }
        const res = await axios.post(
          `${process.env.VUE_APP_API_HOST}/api/v1/cportal/order-inquiries`,
          fd,
          {
            headers: {
              authorization: getters.bearerToken,
            },
          }
        );

        if (res.status === 200) {
          dispatch("addAlert", {
            ...alertTypes.success,
            alert: "inquiry sent!",
          });
        }
        commit(mutations.SET_LOADING, false);
        return true;
      } catch (e) {
        commit(mutations.SET_LOADING, false);
        errorHandler(e);
      }
    },
  },
  getters: {
    orders(state) {
      return state.orders;
    },
    selectedOrder(state) {
      return state.selectedOrder;
    },
    currentOrderPage(state) {
      return state.page;
    },
    totalOrderPages(state) {
      return state.totalPages;
    },
    orderFilters(state) {
      return state.filters;
    },
    orderOutsandingFilter(state) {
      return state.outstandingFilter;
    },
    orderSearchString(state) {
      return state.orderSearchString;
    },
    orderSort(state) {
      return state.orderSort;
    },
  },
};

export default orderStore;
