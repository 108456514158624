export default {
  // Auth
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  REMOVE_AUTH_TOKEN: "REMOVE_AUTH_TOKEN",
  REMOVE_CURRENT_USER: "REMOVE_CURRENT_USER",

  // Alerts
  APPEND_ALERTS: "APPEND_ALERTS",
  REMOVE_ALERT: "REMOVE_ALERT",

  // UI
  SET_LOADING: "SET_LOADING",

  // parts manuals & catalog
  SET_PART_MANUALS: "SET_PART_MANUALS",
  SET_PART_MANUAL_SEARCH_VAL: "SET_PART_MANUAL_SEARCH_VAL",
  SET_BRANDS: "SET_BRANDS",
  SET_MANUAL_FOLDERS: "SET_MANUAL_FOLDERS",
  SET_CATALOGS: "SET_CATALOGS",
  SET_CATALOG_FOLDERS: "SET_CATALOG_FOLDERS",

  // Orders
  SET_ORDER_SEARCH_STRING: "SET_ORDER_SEARCH_STRING",
  SET_ORDERS: "SET_ORDERS",
  SET_SELECTED_ORDER: "SET_SELECTED_ORDER",
  UPDATE_ORDER_IN_LIST: "UPDATE_ORDER_IN_LIST",
  SET_PAGE: "SET_PAGE",
  SET_TOTAL_PAGES: "SET_TOTAL_PAGES",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  CLEAR_STATUS_FILTERS: "CLEAR_STATUS_FILTERS",
  ADD_FILTERS: "ADD_FILTERS",
  SET_OUTSTANDING_FILTER: "SET_OUTSTANDING_FILTER",
  CLEAR_OUTSTANDING_FILTER: "CLEAR_OUTSTANDING_FILTER",
  SET_ORDER_SORT: "SET_ORDER_SORT",
  CLEAR_ORDER_SORT: "CLEAR_ORDER_SORT",

  // Statistics
  SET_ORDER_STATUS_STATS: "SET_ORDER_STATUS_STATS",
  SET_PLACED_ORDER_STATS: "SET_PLACED_ORDER_STATS",
  SET_PAYMENT_STATS: "SET_PAYMENT_STATS",

  // Support Tickets
  SET_SUPPORT_TICKETS: "SET_SUPPORT_TICKETS",
  ADD_SUPPORT_TICKET: "ADD_SUPPORT_TICKET",
  UPDATE_SUPPORT_TICKET: "UPDATE_SUPPORT_TICKET",
};
